import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23')
];

export const server_loads = [0,2,3,4,5,6];

export const dictionary = {
		"/": [~7],
		"/(user)/campaigns": [8,[2,3]],
		"/(user)/campaigns/new": [~15,[2,3]],
		"/(user)/campaigns/[campaignId]": [9,[2,3,4]],
		"/(user)/campaigns/[campaignId]/sessions": [~10,[2,3,4]],
		"/(user)/campaigns/[campaignId]/sessions/record": [~13,[2,3,4]],
		"/(user)/campaigns/[campaignId]/sessions/[sessionId]/summary": [~11,[2,3,4]],
		"/(user)/campaigns/[campaignId]/sessions/[sessionId]/transcript": [~12,[2,3,4]],
		"/(user)/campaigns/[campaignId]/share-creations": [~14,[2,3,4]],
		"/(user)/creations": [~16,[2,5]],
		"/(user)/creations/[campaignId]": [~17,[2,5,6]],
		"/(user)/creations/[campaignId]/create": [~20,[2,5,6]],
		"/(user)/creations/[campaignId]/create/[creationType]": [~21,[2,5,6]],
		"/(user)/creations/[campaignId]/[creationId]": [~18,[2,5,6]],
		"/(user)/creations/[campaignId]/[creationId]/edit": [~19,[2,5,6]],
		"/(user)/intro": [22,[2]],
		"/(user)/invitations": [~23,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';